<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" md="2">
                        <v-select
                            prepend-icon="mdi-account"
                            v-model="member"
                            :items="availableMembers"
                            single-line
                            dense
                            clearable
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <tracker-selector
                            prepend-icon="mdi-bug"
                            v-model="tracker"
                            dense
                            single-line
                            type="name"
                            clearable
                        ></tracker-selector>
                    </v-col>
                    <v-col cols="12" md="2" offset-md="6">
                        <v-select prepend-icon="mdi-calendar" v-model="year" :items="availableYears" dense></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text>
            <v-data-table
                :calculate-widths="true"
                :headers="cols"
                :items="items"
                :loading="loading"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:[`header.total`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(-1) }} h
                </template>

                <template v-slot:[`header.0`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(0) }} h
                </template>
                <template v-slot:[`header.1`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(1) }} h
                </template>
                <template v-slot:[`header.2`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(2) }} h
                </template>
                <template v-slot:[`header.3`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(3) }} h
                </template>
                <template v-slot:[`header.4`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(4) }} h
                </template>
                <template v-slot:[`header.5`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(5) }} h
                </template>
                <template v-slot:[`header.6`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(6) }} h
                </template>
                <template v-slot:[`header.7`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(7) }} h
                </template>
                <template v-slot:[`header.8`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(8) }} h
                </template>
                <template v-slot:[`header.9`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(9) }} h
                </template>
                <template v-slot:[`header.10`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(10) }} h
                </template>
                <template v-slot:[`header.11`]="{ header }">
                    {{ header.text }}
                    <br />
                    {{ totalByMonths(11) }} h
                </template>

                <template v-slot:[`item.module`]="{ item }">
                    <strong>{{ item.module }}</strong>
                </template>

                <template v-slot:[`item.0`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.1`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.2`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.3`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.4`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.5`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.6`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.7`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.8`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.9`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.10`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.11`]="{ value }">
                    <strong v-if="value > 0">{{ value }}</strong>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.total`]="{ value }">
                    <strong class="blue--text text--darken-1" v-if="value > 0">{{ value }} h</strong>
                    <span v-else>-</span>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/utils/api';
import { formatDateMixin } from '@/mixins/dates';
import SortBy from 'lodash/sortBy';
import Round from 'lodash/round';
import Range from 'lodash/range';
import UpperFirst from 'lodash/upperFirst';
import startOfToday from 'date-fns/startOfToday';
import startOfYear from 'date-fns/startOfYear';
import addMonths from 'date-fns/addMonths';
import parseISO from 'date-fns/parseISO';
import trackerSelector from '@/components/selectors/simple/tracker';

export default {
    name: 'project-analytics-timeByUser',
    data: () => ({
        loading: false,
        times: [],
        year: new Date().getFullYear(),
        member: undefined,
        tracker: undefined,
    }),
    mixins: [formatDateMixin],
    components: { trackerSelector },
    computed: {
        ...mapGetters(['users', 'trackers', 'projects']),
        availableYears() {
            return Range(2014, new Date().getFullYear() + 1, 1);
        },
        availableMembers() {
            if (this.users) {
                return SortBy(
                    this.users.map((u) => {
                        return {
                            value: u.name,
                            text: u.fullname,
                        };
                    }),
                    ['text'],
                    ['asc'],
                );
            }
            return [];
        },
        months() {
            return Range(0, 12, 1).map((m) => {
                return {
                    value: '' + m,
                    text: UpperFirst(this.formatDate(addMonths(startOfYear(startOfToday()), m), 'MMM')),
                    sortable: false,
                    align: 'center',
                };
            });
        },
        cols() {
            return [
                {
                    value: 'module',
                    text: this.$t('issues.attributes.module'),
                    sortable: true,
                    align: 'left',
                },
            ]
                .concat(this.months)
                .concat({
                    value: 'total',
                    text: 'TOTAL',
                    sortable: false,
                    align: 'center',
                });
        },
        modules() {
            return this.times ? [...new Set(this.times.map((t) => this.projectModuleTime(t)))] : [];
        },
        items() {
            return SortBy(
                [''].concat(this.modules).map((m) => {
                    let times = {
                        module: m,
                    };

                    this.months.forEach((y) => {
                        times[y.value] = this.times
                            .filter(
                                (t) =>
                                    parseISO(t.date).getMonth() === parseInt(y.value) &&
                                    this.projectModuleTime(t) === m,
                            )
                            .reduce((a, b) => Round(a + b.time, 2), 0);
                    });

                    times.total = this.times
                        .filter((t) => this.projectModuleTime(t) === m)
                        .reduce((a, b) => Round(a + b.time, 2), 0);

                    return times;
                }),
                ['module'],
                ['asc'],
            );
        },
    },
    async mounted() {
        await this.findUsers();
        await this.findProjects();
        this.updateTimes();
    },
    watch: {
        year: function () {
            this.updateTimes();
        },
        member: function () {
            this.updateTimes();
        },
        tracker: function () {
            this.updateTimes();
        },
    },
    methods: {
        ...mapActions(['findProjects', 'findUsers']),
        projectModuleTime(t) {
            const moduleTime = t.module ? ' - ' + t.module : '';

            return this.projectToLabel(t.project) + moduleTime;
        },
        projectToLabel(name) {
            if (this.projects) {
                return this.projects.find((p) => p.name === name).label;
            }

            return name;
        },
        async updateTimes() {
            this.loading = true;

            const query = [
                {
                    field: 'tracking.date',
                    operator: '$dateeqYear',
                    value: this.year,
                },
            ];

            if (this.member) {
                query.push({
                    field: 'tracking.createdBy',
                    operator: '$eq',
                    value: this.member,
                });
            }

            if (this.tracker) {
                query.push({
                    field: 'tracker',
                    operator: '$eq',
                    value: this.tracker,
                });
            }

            const gettingTimes = await API.post('analytics/times', { filters: query });

            this.times = gettingTimes.data.datas;

            this.loading = false;
        },
        totalByMonths(month) {
            if (month >= 0) {
                return this.times
                    .filter((t) => parseISO(t.date).getMonth() === parseInt(month))
                    .reduce((a, b) => Round(a + b.time, 2), 0);
            } else {
                return this.times.reduce((a, b) => Round(a + b.time, 2), 0);
            }
        },
    },
};
</script>

<style></style>
